<div class="main-layout mx-auto">
  <app-layout-header *ngIf="headerConfig.useHeader"></app-layout-header>
  <div class="main-content" [ngClass]="{'no-header': !headerConfig.useHeader}">
    <!--<div class="p-3 flex flex-wrap gap-3">-->
    <!--  <i class="icon icon-arrow-left"></i>-->
    <!--  <i class="icon icon-burger"></i>-->
    <!--  <i class="icon icon-grid"></i>-->
    <!--  <i class="icon icon-list"></i>-->
    <!--  <i class="icon icon-arrow-right"></i>-->
    <!--  <i class="icon icon-avatar"></i>-->
    <!--  <i class="icon icon-booking"></i>-->
    <!--  <i class="icon icon-close"></i>-->
    <!--  <i class="icon icon-heart-filled"></i>-->
    <!--  <i class="icon icon-locker"></i>-->
    <!--  <i class="icon icon-logout"></i>-->
    <!--  <i class="icon icon-mail"></i>-->
    <!--  <i class="icon icon-plus"></i>-->
    <!--  <i class="icon icon-smart-phone"></i>-->
    <!--</div>-->

    <router-outlet></router-outlet>
    <app-layout-footer></app-layout-footer>
  </div>
</div>
