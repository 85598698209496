import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { LANGUAGE_KEY } from 'src/app/app.module';

@Component({
    selector: 'app-layout-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  languageOptions: SelectItem[] = [
    {
      label: "Français",
      value: "fr"
    },
    {
      label: "English",
      value: "en"
    }
  ];

  stop$: Subject<any> = new Subject<any>();
  form: FormGroup;

  private readonly formBuilder = inject(FormBuilder);
  private readonly translateService = inject(TranslateService);

  ngOnInit(): void {
    this._initForm();
  }

  ngOnDestroy(): void {
    this.stop$.next(null);
    this.stop$.complete();
  }

  _initForm(): void {
    this.form = this.formBuilder.group({
      language: [localStorage.getItem(LANGUAGE_KEY) ?? "fr"],
    });

    this.form.valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      this.translateService.use(value.language);
      localStorage.setItem(LANGUAGE_KEY, value.language);
    });
  }

  get language(): string {
    return this.form.value.language;
  }

  get fullYear(): number {
    return (new Date().getFullYear());
  }
}
