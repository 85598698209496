import { Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { HeaderConfig } from '@core/models/header-config';
import { AuthService } from '@core/services/auth.service';
import { LayoutService } from '@core/services/layout.service';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-layout-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  private readonly layoutService = inject(LayoutService);
  private readonly locationService = inject(Location);
  private readonly authService = inject(AuthService);
  private readonly confirmationService = inject(ConfirmationService);

  get headerConfig(): HeaderConfig {
    return this.layoutService.headerConfig$();
  }

  goBack(): void {
    this.locationService.back();
  }

  doLogout(): void {
    this.authService.logout();
  }

  confirmLogout(): void {
    this.confirmationService.confirm({
      accept: () => this.doLogout()
    });
  }
}
