import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LAYOUT_COMPONENTS } from './components';
import { LayoutRoutingModule } from './layout-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';


@NgModule({
  imports: [
    RouterModule,
    LayoutRoutingModule,
    CommonModule,
    TranslateModule,
    DropdownModule,
    IconFieldModule,
    InputIconModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
  ],
  declarations: [...LAYOUT_COMPONENTS],
  providers: [ConfirmationService],
})
export class LayoutModule {}
