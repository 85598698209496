import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CORE_SERVICES } from "./services";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CORE_GUARDS } from "./guards";
import { CORE_RESOLVERS } from "./resolvers";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { LoaderInterceptor } from "./interceptors/loader.interceptor";

@NgModule({
  imports: [BrowserAnimationsModule],
  providers: [
    ...CORE_SERVICES,
    ...CORE_GUARDS,
    ...CORE_RESOLVERS,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  exports: [BrowserAnimationsModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
