import {inject, Injectable} from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from "@core/services/loader.service";

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
    private static counter: number = 0;
    private readonly loaderService = inject(LoaderService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        LoaderInterceptor.counter++;
        this.loaderService.emitIsLoading(true);

        return next.handle(req).pipe(
          finalize(()=> {
              LoaderInterceptor.counter--;
              if (LoaderInterceptor.counter == 0) {
                this.loaderService.emitIsLoading(false);
              }
          }),
        )
    }
}
