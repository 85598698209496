<div class="Footer flex flex-column gap-4">
  <a class="text-xxs" [routerLink]="'#'">Conditions Générales d’Utilisation</a>
  <a class="text-xxs" [routerLink]="'#'">Mentions légales</a>
  <p class="text-xxs m-0">© GuestUp, {{ fullYear }}.</p>
  <div [formGroup]="form" class="flex gap-3">
    <p-iconField class="flex-1" iconPosition="left">
      <span class="p-input-icon pointer-events-none">
        <img class="flag w-1" [src]="'assets/imgs/flags/' + language + '-flag.png'" alt="Flag">
      </span>
      <p-dropdown class="w-5" styleClass="w-full" [options]="languageOptions" formControlName="language" placeholder="-" />
    </p-iconField>
  </div>
</div>
