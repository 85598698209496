import { AuthService } from './auth.service';
import { GuestService } from './guest.service';
import { LayoutService } from './layout.service';
import { LoaderService } from './loader.service';
import { PassfeetService } from './passfeet.service';
import { ScanCodeService } from './scan-code.service';
import { UserService } from './user.service';

export const CORE_SERVICES = [
  LayoutService,
  LoaderService,
  UserService,
  AuthService,
  ScanCodeService,
  GuestService,
  PassfeetService,
];
