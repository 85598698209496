import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, GuardResult, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@core/services/auth.service";
import { UserService } from "@core/services/user.service";
import { Observable, of } from "rxjs";

@Injectable()
export class IsLoggedInGuard implements CanActivate {

  private readonly authService = inject(AuthService);
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GuardResult> {

    if (!this.authService.getToken() || !this.userService.user) {
      this.router.navigate(["/"]);
      return of(false);
    }

    return of(true);
  }
}
