import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { ScanCodeResolver } from '@core/resolvers/scan-code.resolver';
import { IsLoggedInGuard } from '@core/guards/is-logged-in.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    resolve: [ScanCodeResolver],
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import("@features/auth/auth.module").then(m => m.AuthModule),
      },
      {
        path: 'home',
        canActivate: [IsLoggedInGuard],
        loadChildren: () => import("@features/home/home.module").then(m => m.HomeModule),
      },
      {
        path: 'add-guests',
        canActivate: [IsLoggedInGuard],
        loadChildren: () => import("@features/add-guests/add-guests.module").then(m => m.AddGuestsModule),
      },
      {
        path: 'link-guests',
        canActivate: [IsLoggedInGuard],
        loadChildren: () => import("@features/link-guests/link-guests.module").then(m => m.LinkGuestsModule),
      },
      {
        path: '**',
        loadChildren: () => import("@features/page-not-found/page-not-found.module").then(m => m.PageNotFoundModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
