import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { combineLatest, Subject, filter, interval, take } from 'rxjs';

export const documentReadyStateComplete$ = new Subject<void>();
document.onreadystatechange = (): void => {
    if (document.readyState === 'complete') {
        documentReadyStateComplete$.next();
        documentReadyStateComplete$.complete();
    }
};

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  title = 'Mypassfeet';
  player: AnimationPlayer;

  constructor(private animationBuilder: AnimationBuilder) {}

  ngOnInit(): void {
    this._handleSplashScreen();
  }

  private _handleSplashScreen(): void {
      const splashScreen: HTMLElement | null = document.querySelector('.Splash');
      if (splashScreen) {
          if (!environment.splashScreen) {
             splashScreen.remove();

             return;
          }

          const splashScreenDuration = 1500;
          const animationDuration = 400;

          this.player = this.animationBuilder
              .build(animate(animationDuration, style({ opacity: 0 })))
              .create(splashScreen);

          combineLatest([
              documentReadyStateComplete$,
              interval(splashScreenDuration).pipe(take(1)),
          ]).subscribe(() => {
              this.player.play();

              setTimeout(() => {
                  splashScreen.remove();
              }, animationDuration);
          });
      }
  }
}
