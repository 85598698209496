<header
  class="header-wrapper relative flex justify-content-center align-items-center"
>
  <i
    *ngIf="headerConfig.useBackButton"
    class="backBtn absolute icon icon-arrow-left"
    (click)="goBack()"
  ></i>
  <div class="flex flex-column align-items-center gap-2">
    <img class="logo" src="assets/imgs/logo.png" alt="Skiset Logo" />
    <span class="info">{{ "COMMON.by" | translate }} Skiset</span>
  </div>
  <i
    *ngIf="headerConfig.useLogoutButton"
    (click)="confirmLogout()"
    class="logoutBtn absolute icon icon-logout"
  ></i>
</header>
<p-confirmDialog #cd>
  <ng-template pTemplate="headless" let-message>
    <div class="confirm-logout flex flex-column align-items-center p-4 surface-overlay border-round">
      <span class="header text-xs font-medium block">
        {{ 'COMMON.disconnect' | translate }}
      </span>
      <p class="message mb-0 text-xs font-normal text-center">{{ 'COMMON.confirm_logout_msg' | translate }}</p>
      <div class="flex align-items-center gap-2 mt-4">
        <button
          pButton
          [label]="'COMMON.cancel' | translate"
          (click)="cd.reject()"
          severity="secondary"
          [rounded]="true"
          class="p-button-outlined w-8rem ">
        </button>
        <button
          pButton
          [label]="'COMMON.logout' | translate"
          (click)="cd.accept()"
          severity="success"
          [rounded]="true"
          class="w-8rem">
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
