import { Component } from '@angular/core';
import { HeaderConfig } from '@core/models/header-config';
import { LayoutService } from '@core/services/layout.service';

@Component({
    selector: 'app-layout-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {

  constructor(private layoutService: LayoutService) {}

  get headerConfig(): HeaderConfig {
    return this.layoutService.headerConfig$();
  }
}
