import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { ScanCodeService } from "@core/services/scan-code.service";
import { Observable, of } from "rxjs";

@Injectable()
export class ScanCodeResolver implements Resolve<any> {
  private readonly scanCodeService = inject(ScanCodeService);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const { c, i } = route.queryParams;
    if (c && i) {
      this.scanCodeService.scanCode = {
        scan3d_code: c,
        scan3d_id: i
      };
    }

    return of({});
  }
}
